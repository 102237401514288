import React from "react";
import { Outlet } from "react-router-dom";

const MasterPage: React.FC = () => {
  //   const handleSignOut = () => {
  //     signOut(); // Sign out the user from AWS Cognito
  //     logout(); // Update the AuthContext state
  //     navigate("/login"); // Navigate to the login page
  //   };

  return <Outlet />;
};

export default MasterPage;
