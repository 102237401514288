import Logo from "../logo/logo";
import scLogo from "../../assets/scLogo.png";
import CallingCard, { CallingOption } from "../calling-card/calling-card";
import { useEffect, useState } from "react";
import { useAuth } from "../../auth/AuthContext";
import tonikLogo from "../../assets/tonik.png";
import CopyRight from "../atoms/copy-right/copy-right";

const Call: React.FC = () => {
  const { user } = useAuth();
  const [aNumber, setANumber] = useState("");
  const [callOption, setCallOption] = useState<CallingOption>(
    CallingOption.InAppCall
  );

  function handleCallStarted(id: string): void {}

  function handleCallEnded(id: string): void {}

  useEffect(() => {
    const fromNumber = user?.num!;
    const fromNumberParts = fromNumber.split("_");

    if (fromNumberParts.length === 1) {
      setANumber(fromNumber);
      setCallOption(CallingOption.InAppCall);
    } else if (fromNumberParts.length === 2 && fromNumberParts[1] === "PSTN") {
      setANumber(fromNumberParts[0]);
      setCallOption(CallingOption.PSTNCall);
    }
  }, []);

  return (
    <>
      <div className="flex items-center justify-center h-screen bg-gradient-to-r from-sky-800 to-sky-950">
        <div className="bg-slate-200 min-w-[450px] p-8 rounded-2xl shadow-lg text-center bg-gradient-to-tl from-slate-800 to-sky-900 border-4 border-sky-800">
          <div className="flex items-center justify-center space-x-4 mb-4">
            <Logo logo={tonikLogo}></Logo>
          </div>
          <CallingCard
            id="general"
            isDisabled={false} // Disable if another call is active
            logo={""}
            businessName="Tonik Bank"
            aNumber={aNumber}
            allowedIntents={[
              "Credit card",
              "Saving's account",
              "Transaction Dispute",
              "Custom",
            ]}
            callStarted={(id) => handleCallStarted(id)}
            callEnded={(id) => handleCallEnded(id)}
            callingOption={callOption}
          />
          <div className="mt-12">
            <Logo logo={scLogo} h="h-12" w="w-12"></Logo>
            <CopyRight></CopyRight>
          </div>
        </div>
      </div>
    </>
  );
};

export default Call;
