import React from "react";

// Define the props interface for Progress Modal
interface ProgressProps {
  label?: string; // Optional text to display
  isVisible: boolean; // Determines if the modal should be visible
}

// Progress Modal Component with Spinner
const ProgressModal: React.FC<ProgressProps> = ({ label, isVisible }) => {
  if (!isVisible) return null; // Don't render the modal if not visible

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96 ">
        {/* Optional Label */}
        {label && (
          <span className="font-Poppins text-gray-700 block mb-2 text-center font-semibold">
            {label}
          </span>
        )}

        {/* Spinner */}
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid"></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressModal;
