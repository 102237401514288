import React, { useState } from "react";
import CallingCard, { CallingOption } from "../calling-card/calling-card";
import abLogo from "../../assets/ab.png";
import fdLogo from "../../assets/fd.png";
import vdLogo from "../../assets/vd.png";
import scLogo from "../../assets/scLogo.png";
import vonageLogo from "../../assets/vonage.png";
import appQrCode from "../../assets/appQr.png";
import androidQRCode from "../../assets/AndroidQR-Code.png";
import Call from "../call/call";

const MultiCall: React.FC = () => {
  // State to track which call is active
  const [activeCallId, setActiveCallId] = useState<string | null>(null);

  const handleCallStarted = (id: string) => {
    setActiveCallId(id); // Disable other cards when a call starts
    console.log(`Call started for ${id}`);
  };

  const handleCallEnded = (id: string) => {
    setActiveCallId(null); // Re-enable all cards when the call ends
    console.log(`Call ended for ${id}`);
  };

  return (
    <>
      <div className="bg-gradient-to-tr from-slate-950 to-sky-900 flex flex-col items-center justify-center min-h-svh w-screen pt-2">
        {/* Center the logo */}
        <div className="flex justify-center items-center mb-4 gap-1">
          <img
            src={scLogo}
            alt="Logo"
            className="rounded-lg w-24 h-24 border-2"
          />
          <img
            src={vonageLogo}
            alt="Logo"
            className="rounded-lg w-24 h-24 border-2"
          />
        </div>
        {/* Center the CallingCards */}
        <div className="flex items-center justify-center gap-10 p-2">
          <div className="flex flex-col items-center gap-2">
            <span className="font-Poppins text-slate-200 text-2xl uppercase">
              Demo for Bank
            </span>
            <CallingCard
              id="ab"
              isDisabled={activeCallId !== null && activeCallId !== "ab"} // Disable if another call is active
              logo={abLogo}
              businessName="Alien bank"
              aNumber="12037796621"
              allowedIntents={[
                "Credit card",
                "Saving's account",
                "Transaction Dispute",
                "Custom",
              ]}
              callStarted={(id) => handleCallStarted(id)}
              callEnded={(id) => handleCallEnded(id)}
              callingOption={CallingOption.InAppCall}
            />
          </div>

          <div className="flex flex-col items-center gap-2">
            <span className="font-Poppins text-slate-200 text-2xl uppercase">
              Demo for Logistics
            </span>
            <CallingCard
              id="fd"
              isDisabled={activeCallId !== null && activeCallId !== "fd"} // Disable if another call is active
              logo={fdLogo}
              businessName="Fast Delivery"
              aNumber="61285275054"
              allowedIntents={[
                "Package is out for delivery",
                "Delivery delayed",
                "Failed Delivery Attempt",
                "Delivery Confirmation Call",
                "Custom",
              ]}
              callStarted={(id) => handleCallStarted(id)}
              callEnded={(id) => handleCallEnded(id)}
              callingOption={CallingOption.InAppCall}
            />
          </div>

          <div className="flex flex-col items-center gap-2">
            <span className="font-Poppins text-slate-200 text-2xl uppercase">
              Demo for Government services
            </span>
            <CallingCard
              id="vd"
              isDisabled={activeCallId !== null && activeCallId !== "vd"} // Disable if another call is active
              logo={vdLogo}
              businessName="Visa Department"
              aNumber="12012413481"
              allowedIntents={[
                "Missing document check",
                "Medical checkup required",
                "Document Verification Request",
                "Application Status Update (Pending/Review)",
                "Application Approval Notification",
                "Custom",
              ]}
              callStarted={(id) => handleCallStarted(id)}
              callEnded={(id) => handleCallEnded(id)}
              callingOption={CallingOption.InAppCall}
            />
          </div>
        </div>
        {/* footer */}
        <div className="flex flex-col items-center justify-center gap-2">
          <span className="text-white text-[24px]">
            Download the "Secured calls" demo application
          </span>
          <div className="flex items-center justify-center text-center gap-8">
            <div className="flex flex-col items-center justify-center text-center">
              <img className="h-24 w-24" src={appQrCode} alt="" />
              <span className="bg-slate-600 w-24 text-white p-1 font-medium">
                iOS
              </span>
            </div>
            {/* <div className="flex flex-col items-center justify-center text-center">
              <img className="h-24 w-24" src={androidQRCode} alt="" />
              <span className="bg-slate-600 w-24 text-white p-1 font-medium">
                Android
              </span>
            </div> */}
          </div>
          <div className="flex flex-col items-center justify-center">
            <span className="font-Poppins font-normal text-white">
              © 2024 Expertstack Pty Ltd | All Rights Reserved.
            </span>
            <a
              href="mailto:hello@securedcalls.com?subject=Inquiry%20about%20Secured%20Calls"
              className="font-normal text-white"
            >
              hello@securedcalls.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiCall;
