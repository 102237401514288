import React, { useState } from "react";

interface AlertLabelProps {
  type: "info" | "warning" | "error" | "success"; // Different message types
  message: string; // The message to display
  onHide: () => void; // Function to handle input changes
}

const AlertLabel: React.FC<AlertLabelProps> = ({ type, message, onHide }) => {
  const [visible, setVisible] = useState(true); // State to control visibility

  // Define styles based on the message type
  const baseStyles =
    "p-1 rounded-sm mb-4 text-sm flex items-center justify-center relative w-full";

  const typeStyles = {
    info: "bg-blue-800 text-white border border-blue-300",
    warning: "bg-yellow-800 text-white border border-yellow-300",
    error: "bg-red-800 text-white border border-red-300",
    success: "bg-green-800 text-white border border-green-300",
  };

  // Hide the message when the cross button is clicked
  if (!visible) return null;

  return (
    <div className={`${baseStyles} ${typeStyles[type]}`}>
      {/* Message Text */}
      <span className="flex-grow text-center">{message}</span>

      {/* Close Button */}
      <button
        className="absolute right-2 text-gray-700 hover:text-gray-900 focus:outline-none"
        onClick={() => {
          setVisible(false);
          onHide();
        }}
      >
        &times; {/* Unicode character for a cross (×) */}
      </button>
    </div>
  );
};

export default AlertLabel;
