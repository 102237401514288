// src/components/PageNotFound.tsx
import { Stethoscope } from "lucide-react";
import React from "react";

const PageNotFound: React.FC = () => {
  return (
    <div className="bg-slate-800 h-screen flex flex-col items-center justify-center text-white">
      <Stethoscope size={220} />
      <br></br>
      <span>404 - Page Not Found</span>
      <span>
        The page you are looking for might have been removed or is temporarily
        unavailable.
      </span>
      <button>Go to Home</button>
    </div>
  );
};

export default PageNotFound;
