import { useNavigate } from "react-router-dom";

const CallChoice: React.FC = () => {
  const navigate = useNavigate();

  const handleOption = (option: string) => {
    navigate(option);
  };

  return (
    <>
      <div className="flex items-center justify-center h-screen bg-slate-800)">
        <div className="bg-slate-200 min-w-[450px] p-8 rounded-lg shadow-lg text-center bg-gradient-to-tr from-slate-950 to-sky-900">
          <div className="flex items-center justify-center gap-3 bg-slate-800)">
            <button
              className="bg-slate-950 text-white p-3 rounded-3xl"
              onClick={() => handleOption("/call")}
            >
              Standard demo
            </button>
            {/* <button
              className="bg-slate-950 text-white p-3 rounded-3xl"
              onClick={() => handleOption("/hyundai-motors")}
            >
              Hyundai motors demo
            </button> */}
            <button
              className="bg-slate-950 text-white p-3 rounded-3xl"
              onClick={() => handleOption("/call-v1")}
            >
              Multi call demo
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallChoice;
