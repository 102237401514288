import React, { ReactNode, useState } from "react";
import { X } from "lucide-react";

interface InputWithIconProps {
  icon: ReactNode; // Allows passing any icon as a prop
  label: string; // Label text
  placeholder?: string; // Optional placeholder
  maxlength?: number;
  value: string; // The input's value
  onChange: (value: string) => void; // Function to handle input changes
  onClear?: () => void; // Optional clear function
}

const InputWithIcon: React.FC<InputWithIconProps> = ({
  icon,
  label,
  placeholder = "Enter value",
  maxlength,
  value,
  onChange,
  onClear,
}) => {
  const [enteredValue, setEnteredValue] = useState("");

  return (
    <div className="flex flex-col w-full">
      <div className="relative">
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          {icon}
        </span>
        <input
          type="text"
          className="pl-10 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent w-full"
          placeholder={placeholder}
          value={enteredValue}
          maxLength={maxlength}
          onChange={(e) => {
            setEnteredValue(e.target.value);
            onChange(e.target.value);
          }}
        />
        {value && onClear && (
          <span
            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            onClick={() => {
              setEnteredValue("");
              onClear();
            }}
          >
            <X className="h-5 w-5 text-red-600" />
          </span>
        )}
      </div>
    </div>
  );
};

export default InputWithIcon;
