import { useState } from "react";
import Logo from "../logo/logo";
import scLogo from "../../assets/scLogo.png";
import { useNavigate } from "react-router-dom";
import InputWithIcon from "../atoms/input-with-icon/input-with-icon";
import { ChevronLeft, ChevronRight, Mail } from "lucide-react";
import { forgotPassword } from "../../auth";

const RequestCode: React.FC = () => {
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleBack = () => {
    navigate("/login");
  };

  const handleNext = () => {
    setLoading(true);
    forgotPassword(emailId, (err, result) => {
      if (err) {
        setLoading(false);
        setError(err.message || JSON.stringify(err));
      } else {
        setLoading(false);
        navigate("/enter-code", {
          state: { emailId },
        });
      }
    });
  };

  return (
    <>
      <div className="flex items-center justify-center h-screen bg-slate-800 ">
        <div className="bg-slate-200 p-8 rounded-lg shadow-lg text-center bg-gradient-to-tr from-slate-950 to-sky-900 max-w-[450px]">
          <div className="flex flex-col items-center justify-center gap-6 bg-slate-800)">
            <Logo logo={scLogo}></Logo>
            <span className="text-white">
              Enter the email that you registered with and we will email you a
              code to be entered on the next screen.
            </span>
            <InputWithIcon
              icon={<Mail className="h-5 w-5 text-gray-600" />}
              label={"Email"}
              placeholder="Registered email address"
              value={emailId}
              onChange={function (value: string): void {
                setEmailId(value);
              }}
              onClear={() => {}}
            ></InputWithIcon>
            {/* <input
              type="text"
              className="p-2 border rounded w-full"
              placeholder="Email address"
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
            /> */}
            <div className="flex gap-2">
              <button
                onClick={handleBack}
                className="font-Poppins p-2 min-w-32 bg-orange-500 text-white rounded hover:bg-orange-800 uppercase drop-shadow-lg"
              >
                <span className="flex items-center justify-center gap-1 text-center">
                  <ChevronLeft />
                  Back
                </span>
              </button>
              <button
                onClick={handleNext}
                className="font-Poppins p-2 min-w-32 bg-blue-500 text-white rounded hover:bg-blue-800 uppercase drop-shadow-lg"
              >
                <span className="flex items-center justify-center gap-1 text-center">
                  Next <ChevronRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestCode;
