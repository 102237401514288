import { ChevronLeft, ChevronRight, FileDigit } from "lucide-react";
import scLogo from "../../assets/scLogo.png";
import InputWithIcon from "../atoms/input-with-icon/input-with-icon";
import Logo from "../logo/logo";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmationCode } from "../../auth";
import CopyRight from "../atoms/copy-right/copy-right";
import AlertLabel from "../atoms/alert-label/alert-label";
import ProgressModal from "../molecules/progress-modal/progress-modal";

const EnterCode: React.FC = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const location = useLocation();
  const emailId = location.state?.emailId;

  useEffect(() => {
    if (!emailId) {
      navigate("/login");
    }
  }, [emailId, navigate]);

  const handleBack = () => {
    navigate("/request-code");
  };

  const handleChangePassword = () => {
    // Ensure passwords match before proceeding
    if (!code) {
      setError("Please enter the code!");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    setLoading(true);
    setError(""); // Clear error if passwords match

    confirmationCode(emailId, code, confirmPassword, (err) => {
      setLoading(false);
      if (err) {
        setError(err.message || JSON.stringify(err));
      } else {
        navigate("/login");
      }
    });
  };

  return (
    <>
      <div className="flex items-center justify-center h-screen bg-slate-800 ">
        <div className="bg-slate-200 min-w-[450px] p-8 rounded-lg shadow-lg text-center bg-gradient-to-tr from-slate-950 to-sky-900 max-w-[450px]">
          <div className="flex flex-col items-center justify-center gap-6 bg-slate-800)">
            <Logo logo={scLogo}></Logo>
            <span className="text-white">{emailId}</span>
            <InputWithIcon
              icon={<FileDigit className="h-5 w-5 text-gray-600" />}
              label={"Email"}
              placeholder="Enter your code"
              value={code}
              maxlength={6}
              onChange={function (value: string): void {
                setCode(value);
              }}
              onClear={() => {}}
            ></InputWithIcon>
            <input
              type="password"
              className="p-2 border rounded w-full"
              placeholder="New password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              className="p-2 border rounded w-full"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className="flex gap-2">
              <button
                onClick={handleBack}
                className="font-Poppins p-2 min-w-32 bg-orange-500 text-white rounded hover:bg-orange-800 uppercase drop-shadow-lg"
              >
                <span className="flex items-center justify-center gap-1 text-center">
                  <ChevronLeft />
                  Back
                </span>
              </button>
              <button
                onClick={handleChangePassword}
                className="font-Poppins p-2 min-w-32 bg-blue-500 text-white rounded hover:bg-blue-800 uppercase drop-shadow-lg"
              >
                <span className="flex items-center justify-center gap-1 text-center">
                  Change <ChevronRight />
                </span>
              </button>
            </div>
            <br></br>
            {error && (
              <AlertLabel
                type={"error"}
                message={error}
                onHide={() => {
                  setError("");
                }}
              ></AlertLabel>
            )}
          </div>
          <ProgressModal
            isVisible={loading}
            label="Applying changes"
          ></ProgressModal>
          <CopyRight></CopyRight>
        </div>
      </div>
    </>
  );
};

export default EnterCode;
