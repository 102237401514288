interface LogoProps {
  logo: string;
  businessName?: string;
  h?: string;
  w?: string;
}

const Logo: React.FC<LogoProps> = ({
  logo,
  businessName = "",
  h = "h-24",
  w = "w-24",
}) => {
  return (
    <>
      <div className="flex flex-col items-center mb-4">
        <img
          src={logo}
          alt={businessName}
          className={`rounded-lg drop-shadow-lg ${h} ${w}`}
        />
      </div>
    </>
  );
};

export default Logo;
