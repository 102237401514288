// src/components/SignUp.tsx

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../auth";
import CopyRight from "../atoms/copy-right/copy-right";
import scLogo from "../../assets/scLogo.png";
import Logo from "../logo/logo";
import InputWithIcon from "../atoms/input-with-icon/input-with-icon";
import { Binary, LetterText, Mail } from "lucide-react";
import ProgressModal from "../molecules/progress-modal/progress-modal";
import AlertLabel from "../atoms/alert-label/alert-label";

const SignUp: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);
    console.log(phoneNumber);

    signUp(
      username,
      password,
      givenName,
      familyName,
      phoneNumber,
      (err, result) => {
        if (err) {
          setLoading(false);
          setError(err.message || JSON.stringify(err));
        } else {
          setError("");
          setLoading(false);
          navigate("/verify-code", {
            state: { username, password },
          });
        }
      }
    );
  };

  return (
    <>
      <div className="font-Poppins flex items-center justify-center h-screen bg-slate-800">
        <div className="bg-slate-200 max-w-[450px] min-w-[450px] p-8 rounded-lg shadow-lg text-center bg-gradient-to-tr from-slate-950 to-sky-900">
          <Logo logo={scLogo} businessName="Secured calls"></Logo>
          <h1 className="font-Poppins font-semibold text-xl text-white">
            SIGN UP
          </h1>
          <h2 className="font-Poppins font-semibold text-lg text-white">
            DEMO CONTACT CENTRE
          </h2>

          <br></br>
          <button
            type="button"
            className="font-Poppins text-right text-indigo-400 underline bg-transparent border-none cursor-pointer p-0"
            onClick={handleLogin}
          >
            login with an existing account
          </button>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4">
            <InputWithIcon
              icon={<LetterText className="h-5 w-5 text-gray-600" />}
              label={"Given name"}
              value={givenName}
              placeholder="Given name"
              onChange={function (value: string): void {
                setGivenName(value);
              }}
              onClear={() => {}}
            ></InputWithIcon>
            <InputWithIcon
              icon={<LetterText className="h-5 w-5 text-gray-600" />}
              label={"Family name"}
              placeholder="Family name"
              value={familyName}
              onChange={function (value: string): void {
                setFamilyName(value);
              }}
              onClear={() => {}}
            ></InputWithIcon>
            <InputWithIcon
              icon={<Binary className="h-5 w-5 text-gray-600" />}
              label={"Mobile number"}
              placeholder="Mobile number"
              value={phoneNumber}
              onChange={function (value: string): void {
                setPhoneNumber(() => value);
              }}
              onClear={() => {}}
            ></InputWithIcon>
            <InputWithIcon
              icon={<Mail className="h-5 w-5 text-gray-600" />}
              label={"Email"}
              placeholder="Email address (user name)"
              value={username}
              onChange={function (value: string): void {
                setUsername(value);
              }}
              onClear={() => {}}
            ></InputWithIcon>
            <input
              type="password"
              className="p-2 border rounded"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              className="p-2 border rounded"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            {error && (
              <div>
                <AlertLabel
                  type={"error"}
                  message={error}
                  onHide={() => {
                    setError("");
                  }}
                ></AlertLabel>

                <a
                  href="mailto:hello@securedcalls.com?subject=Registration%20for%20trials"
                  className="font-normal text-white"
                >
                  <span className="flex items-center justify-center gap-1 text-center">
                    <Mail />
                    Support
                  </span>
                </a>
              </div>
            )}

            <button
              type="submit"
              className="font-Poppins p-2 bg-blue-500 text-white rounded hover:bg-blue-800 uppercase"
              onClick={() => {}}
            >
              Create account
            </button>
          </form>
          <br></br>
          <CopyRight></CopyRight>
        </div>
      </div>
      <ProgressModal
        label="Creating the your account!"
        isVisible={loading}
      ></ProgressModal>
    </>
  );
};

export default SignUp;
