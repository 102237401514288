import React, { createContext, useContext, useState, ReactNode } from "react";

export interface LoggedInUser {
  id: string;
  givenName: string;
  familyName: string;
  username: string;
  phoneNumber: string;
  ex_user_id?: string;
  accessToken?: string;
  num?: string;
  accountId: string;
  email: string;
  role?: string;
  [key: string]: any; // Add more properties as needed
}

interface AuthContextType {
  user: LoggedInUser | null;
  isAuthenticated: boolean;
  login: (user: LoggedInUser) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<LoggedInUser | null>(null);

  const login = (user: LoggedInUser) => {
    setUser(user);
  };

  const logout = () => {
    setUser(null);
  };

  const isAuthenticated = !!user;

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
