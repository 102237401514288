import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/protected-routes/protected-route";
import Login from "./components/login/login";
import SignUp from "./components/sign-up/sign-up";
import PageNotFound from "./components/page-not-found/page-not-found";
import MasterPage from "./components/master-page/master-page";
import Call from "./components/call/call";
import CallChoice from "./components/call-choice/call-choice";
import MultiCall from "./components/multi-call/multi-call";
import RequestCode from "./components/request-code/request-code";
import EnterCode from "./components/enter-code/enter-code";
import VerifyCode from "./components/verify-code/verify-code";
import { AuthProvider } from "./auth/AuthContext";
import HyundaiMotors from "./components/hyundai-motors/hyundai-motors";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="font-Poppins bg-slate-800">
          <main>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/request-code" element={<RequestCode />} />
              <Route path="/enter-code" element={<EnterCode />} />
              <Route path="/verify-code" element={<VerifyCode />} />
              <Route path="/" element={<ProtectedRoute />}>
                <Route path="call-choice" element={<CallChoice />} />
                <Route path="hyundai-motors" element={<HyundaiMotors />} />
                <Route path="call-v1" element={<MultiCall />} />
                <Route path="/" element={<MasterPage />}>
                  <Route path="call" element={<Call />} />
                </Route>
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
